section{
    padding: 100px 0;
}
.details-card {
	background: #ecf0f1;
}

.card-content {
    background: #ffffff;
    border: 4px;
    box-shadow: 0 2px 5px 0 rgb(0 0 0 / 16%), 0 2px 10px 0 rgb(0 0 0 / 12%);
    width: 49%;
    border-radius: 10px;
}

.card-img {
	position: relative;
	overflow: hidden;
	border-radius: 0;
	z-index: 1;
}
.icon-set{
    margin-left: auto;
    position: absolute;
    top: 0;
    right: 15px;
    background: white;
    border-radius: 100px;
    padding: 5px;
    width: 2.5rem!important;
    height: 2.5rem!important;
    font-size: 2rem!important;
    margin-top: 4px;
}
.card-img img {
	width: 100%;
	height: auto;
	display: block;
}

.card-img span {
	position: absolute;
    top: 15%;
    left: 12%;
    background: #1ABC9C;
    padding: 6px;
    color: #fff;
    font-size: 12px;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    transform: translate(-50%,-50%);
}
.card-img span h4{
        font-size: 12px;
        margin:0;
        padding:10px 5px;
         line-height: 0;
}


.card-desc h3 {
    color: white;
    font-weight: 600;
    font-size: 1.5em;
    line-height: 1.3em;
    margin-top: 0;
    margin-bottom: 5px;
    padding: 10px;
    background: #1abc9c;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
}
.card-desc h4{
    color: #1abc9c;
    font-weight: 500;
    font-size: 1.2em;
    line-height: 1.3em;
    margin-top: 0;
    margin-bottom: 5px;
}
.card-desc .abc {
    color: #1abc9c;
    font-weight: 400;
    font-size: 1em;
    line-height: 1;
    margin: 0px;
}
.card-desc p {
    color: #747373;
    font-weight: 400;
    font-size: 1em;
    line-height: 1.5;
    margin: 0px;
    padding: 10px;
}
.btn-card{
    width:100%;
	background-color: #1ABC9C;
	color: #fff;
	box-shadow: 0 2px 5px 0 rgba(0,0,0,.16), 0 2px 10px 0 rgba(0,0,0,.12);
    padding: .5rem 2.14rem;
    font-size: 1rem;
    -webkit-transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;
    -o-transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;
    margin: 0;
    border: 0;
    -webkit-border-radius: .125rem;
    border-radius: 30px;
    cursor: pointer;
    text-transform: uppercase;
    white-space: normal;
    word-wrap: break-word;
    color: #fff;
}
.btn-card:hover {
    background: orange;
}
a.btn-card {
    text-decoration: none;
    color: #fff;
}