.sidebar {
  background-color: #232323 !important;
}
.nav-link {
  font-size: 12px !important;
}
.sidebar-nav .nav-link.active {
  border-left: 4px solid #fff;
}

// login page css start
body {
  background: #e9e9e9;
  color: #666666;
  font-family: "Quicksand", sans-serif !important;
  font-size: 14px;
  margin: 0px;
}

/* Main Content Styles */

.login-card {
  width: 50%;
  background: #ffffff;
  //border-radius: 5px;
  // padding: 60px 0 40px 0;
  box-sizing: border-box;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

/* Right Side Card Styles */
.login-card .title {
  position: relative;
  z-index: 1;
  border-left: 5px solid #e65100;
  margin: 0 0 35px;
  padding: 10px 0 10px 50px;
  color: #e65100;
  font-size: 220%;
  font-weight: 600;
  text-transform: uppercase;
}

.login-card .input-container {
  position: relative;
  margin: 0 60px 50px;
}

.login-card .input-container input {
  outline: none;
  z-index: 1;
  position: relative;
  background: none;
  width: 100%;
  height: 60px;
  border: 0;
  color: #212121;
  font-size: 24px;
  font-weight: 400;
}

.login-card .input-container .bar {
  position: absolute;
  left: 0;
  bottom: 0;
  background: #757575;
  width: 100%;
  height: 1px;
}

.login-card .button-container {
  margin: 0 60px;
  margin-bottom: 20px;
  text-align: center;
}

.login-card .button-container button {
  outline: 0;
  cursor: pointer;
  position: relative;
  background: 0;
  width: 70%;
  border: 2px solid #e3e3e3;
  padding: 20px 0;
  font-size: 180%;
  font-weight: 600;
  line-height: 1;
  text-transform: uppercase;
  -webkit-transition: 0.3s ease;
  transition: 0.3s ease;
}

.login-card .button-container button:hover,
.login-card .button-container button:active,
.login-card .button-container button:focus {
  border-color: #e65100;
}

/* Left Side Card Styles */
.info {
  color: white;
  background: url("../assets/images/bg.png") center no-repeat;
  background-size: cover;
  display: flex !important;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  height: 100%;
  flex-direction: column;
}

.info img {
  display: block;
  margin: 0 auto;
}

.info h1 {
  text-align: left;
  font-size: 32px;
  font-weight: 500;
  text-transform: capitalize;
  padding: 0 30px;
}

.info .para {
  font-size: 13px;
  font-weight: 400;
  padding: 4%;
  line-height: 24px;
  opacity: 0.9;
  color: white;
  margin-left: 0;
}

.info .copy {
  text-align: center;
  margin-bottom: 6px;
}

/* Media Queries */
@media only screen and (max-width: 1094px) {
  .container {
    width: 100%;
  }
}

@media only screen and (min-width: 768px) {
  .eq {
    display: table;
  }
  .col {
    float: none;
    display: table-cell;
    vertical-align: top;
  }
}

@media only screen and (max-width: 767px) {
  .container {
    width: 100%;
  }
  .login-card {
    width: 100%;
  }
  .progress .bar {
    width: 1%;
  }
  .progress #right {
    background-color: #fff;
  }
}

/* Animations */
@-webkit-keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-webkit-keyframes slide-up {
  from {
    -webkit-transform: translateY(12px);
    transform: translateY(12px);
  }
  to {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}
@keyframes slide-up {
  from {
    -webkit-transform: translateY(12px);
    transform: translateY(12px);
  }
  to {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

.animated {
  -webkit-animation: slide-up 0.7s ease-in, fade-in 0.7s ease-in;
  animation: slide-up 0.7s ease-in, fade-in 0.7s ease-in;
  -webkit-animation-play-state: running;
  animation-play-state: running;
  -webkit-animation-fill-mode: backwards;
  animation-fill-mode: backwards;
}
// login page css End

:root {
  --primary-color: #00699f;
  --color-input-text: hsl(230, 15%, 75%);
  --color-border: #00699f;
  --bg-color: rgb(252, 252, 255);
  --form-bg: rgb(255, 255, 255);
  --border-radius: 3px;
}
* {
  margin: 0;
  box-sizing: border-box;
}
html {
  height: 100%;
}
//   body{
//     height:100%;
//     background:var(--bg-color);
//     font-family:sans-serif;
//     display:grid;
//     place-items:center;
//   }
.login-form {
  max-width: 420px;
  width: 100%;
  padding: 2rem;
  // background:var(--form-bg);
  border-radius: calc(var(--border-radius) * 2);
}
.client-form h1 {
  font-size: 24px;
  margin-bottom: 30px;
  font-weight: 600;
  text-align: center;
}
.client-form {
  padding: 60px 30px;
  background-color: white;
  justify-content: center;
  align-items: center;
  width: 80%;
  display: flex;
  box-shadow: 4px 3px 6px 4px #eee;
  border-radius: 12px;
  margin: 45px auto;
}
// .client-form .input-floating-label{
//   margin-left: 30px;
// }
.input-floating-label {
  position: relative;
  margin-bottom: 30px;
  width: 100%;
  display: flex;
  padding: 0px 8px;
  // margin: 0 0px 30px 30px;

  .input {
    font-family: inherit;
    width: 100%;
    display: block;
    border: 2px solid var(--color-border);
    border-radius: var(--border-radius);
    outline: 0;
    font-size: 14px;
    color: #424242;
    padding: 8px 16px;
    background: transparent;
    transition: border-color 0.2s;
    margin-bottom: 10px;

    &:required,
    &:invalid {
      box-shadow: none;
    }

    &::placeholder {
      color: transparent;
    }
    &:placeholder-shown {
      border-color: rgb(185, 184, 184);
    }

    &:placeholder-shown ~ label {
      font-size: 12px;
      cursor: text;
      transform: translatey(11px);
      padding: 0px 0px;
      color: hsl(230, 15%, 75%);
      pointer-events: none;
    }
  }
  .input-client {
    font-family: inherit;
    width: 100%;
    display: block;
    border: 0;
    border: 2px solid var(--color-border);
    border-radius: var(--border-radius);
    outline: 0;
    font-size: 14px;
    color: #424242;
    padding: 8px 16px;
    background: transparent;
    transition: border-color 0.2s;
    margin-bottom: 10px;

    &:required,
    &:invalid {
      box-shadow: none;
    }

    &::placeholder {
      color: transparent;
    }
    &:placeholder-shown {
      border-color: rgb(185, 184, 184);
    }

    &:placeholder-shown ~ label {
      font-size: 12px;
      cursor: text;
      transform: translatey(11px);
      padding: 0px 0px;
      color: hsl(230, 15%, 75%);
      pointer-events: none;
    }
  }
  label {
    position: absolute;
    left: 12px;
    display: block;
    transform: translatey(-7px);
    transition: 0.3s;
    font-size: 0.75rem;
    padding: 0px 8px;
    color: hsl(200deg 68% 39%);
    background: var(--form-bg);
    top: 0;
  }

  .input:focus {
    ~ label {
      position: absolute;
      transform: translatey(-7px);
      color: var(--primary-color);
      padding: 0px 8px;
      font-size: 0.75rem;
    }
    border-color: var(--primary-color);
  }
}
.btn-submit {
  padding: 12px 20px;
  border-radius: var(--border-radius);
  border: none;
  background: #00699f;
  font-family: sans-serif;
  // font: size 10px;
  line-height: 1;
  transition: 0.2s;
  cursor: pointer;
  color: #ffff;
  width: 100%;
  font-size: 12px;
}
.btn-submit:focus {
  box-shadow: 0px 0px 4px var(--primary-color);
}
.btn-submit:active {
  transform: scale(1.04) translatey(2px);
}
.forgot {
  align-self: flex-end;
  margin-bottom: 20px;
  margin-top: -20px;
  color: #00699f;
  font-size: 12px;
}
.signup-link {
  color: #00699f;
}
.link {
  margin-top: 50px;
}
.verification-code--inputs input[type="tel"] {
  border: 2px solid #e1e1e1;
  width: 69px;
  height: 69px;
  //padding: 10px;
  text-align: center;
  display: inline-block;
  box-sizing: border-box;
  margin: 10px;
  align-self: flex-end;
  border-top: none;
  border-left: none;
  border-right: none;
  outline: none;
}

.verification-code--inputs input[type="text"]:focus {
  border-bottom: 3px solid #00699f;
}

@media only screen and (max-width: 600px) {
  .verification-code--inputs {
    display: flex;
  }
}

.toggle {
  background: none;
  border: none;
  color: #337ab7;
  /*display: none;*/
  /*font-size: .9em;*/
  font-weight: 600;
  /*padding: .5em;*/
  position: absolute;
  right: 0.75em;
  top: 2.25em;
  z-index: 9;
}

.fa {
  font-size: 2rem;
}
.input-simple {
  font-size: 16px;
  line-height: 1.5;
  border: none;
  background: #ffffff;
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20'><path fill='%23838D99' d='M13.22 14.63a8 8 0 1 1 1.41-1.41l4.29 4.29a1 1 0 1 1-1.41 1.41l-4.29-4.29zm-.66-2.07a6 6 0 1 0-8.49-8.49 6 6 0 0 0 8.49 8.49z'></path></svg>");
  background-repeat: no-repeat;
  background-position: 10px 10px;
  background-size: 20px 20px;
  border: 1px solid #c5cbd5;
  width: 100%;
  padding: 0.5em 1em 0.5em 2.5em;
  margin-bottom: 20px;
  //margin-left: 5px;
  border-radius: 5px;
}
// .btn-primary {
//   background: #3498db;
//   color: #ffffff;
//   width: 250px;
//   height: 40px;
//   border-color: #3498db;
//   border-radius: 3px;

// }
.button {
  background: #e5e5e5;
  color: #100f0f;
  width: 256px;
  height: 45px;
  border-color: #e5e5e5;
  border-radius: 3px;
  text-decoration: none;
}
.btn-secondary {
  background: transparent !important;
  border-color: #ccc !important;
  color: rgb(31, 123, 169) !important;
}
.btn-primary {
  background: rgb(31, 123, 169) !important;
  border-color: rgb(31, 123, 169) !important;
  color: #ffffff;
}
.modal-btn .btn {
  width: 100%;
  height: 40px;
  font-size: 13px;
  border-radius: 3px;
  display: block;
}
// .btn {
//   background: #3498db;
//   color: #ffffff;
//   width: 250px;
//   height: 40px;
//   border-color: #3498db;
//   align-self: flex-end;
//   border-radius: 3px;
//   margin-left: auto;
//   display: block;
//   margin-right: 41px;

// }
.modal-searchbar {
  width: 100%;
  display: flex;
  margin-right: auto;
  margin-bottom: 16px;
}
.modal-searchbar input {
  font-size: 16px;
  line-height: 1.5;
  border: none;
  background: #ffffff;
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20'><path fill='%23838D99' d='M13.22 14.63a8 8 0 1 1 1.41-1.41l4.29 4.29a1 1 0 1 1-1.41 1.41l-4.29-4.29zm-.66-2.07a6 6 0 1 0-8.49-8.49 6 6 0 0 0 8.49 8.49z'></path></svg>");
  background-repeat: no-repeat;
  background-position: 10px 10px;
  background-size: 15px 15px;
  border: 1px solid #c5cbd5;
  width: 100%;
  height: 36px;
  padding: 0.5em 1em 0.5em 2.5em;
  margin-bottom: 0px;
  //margin-left: 5px;
  border-radius: 5px;
  margin-right: 15px;
}
.searchbar {
  width: 60%;
  display: flex;
  margin-left: auto;
  margin-bottom: 16px;
}
.client-head {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
}
.client-head input {
  background-position: 10px 10px;
  background-size: 15px 15px;
  height: 36px;
}
.client-head .btn {
  color: #ffffff;
  height: 36px;
  font-size: 13px;
  padding: 0 30px;
  border-radius: 5px;
}
.card {
  height: 190px;
  //padding: 0px;
}
.abcd {
  background-color: rgb(253, 252, 252);
}
iframe .gCuogY .blockbuilder-branding {
  display: none !important;
  opacity: 0 !important;
  pointer-events: none !important;
  background-color: red;
}

// grape js css / scss

$primaryColor: rgb(251, 251, 251);
$secondaryColor: #909090;
$tertiaryColor: #ff6206;
$quaternaryColor: #e98614;
$colorHighlight: #ff6206;
@import "grapesjs/src/styles/scss/main.scss";

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
#top_panel {
  display: flex;
  align-items: center;
  justify-content: space-between;

  gap: 30px;
  padding: 12px 8px;
  border-bottom: 1px solid #ededed;
}

.panel_action_list {
  display: flex;
  list-style: none;
  align-items: center;
  gap: 12px;
  .panel_action {
    list-style: none;
    display: inline-block;
    line-height: 1;
    vertical-align: middle;
  }
}
#aside-panel {
}
#main_panel {
}
#main_top_panel {
}

.block-icon-container {
  width: 100%;
  display: inline-flex;
  justify-content: center;
  position: relative;
  padding: 8px 0;
  align-content: center;
  color: inherit;
  svg {
    position: relative;
    left: 0;
    width: 50%;
    height: 40px;
    object-fit: contain;
    object-position: bottom;
    top: 0;
    padding: 0;
    margin: 0;
    color: currentColor;
    path,
    rect,
    circle {
      fill: currentColor;
    }
  }
}
.icon {
  background-repeat: no-repeat;
  background-position: center;
  &.icon-sw {
    background-image: url('data:image/svg+xml;utf8,<svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><g><path fill="none" d="M0 0h24v24H0z"></path><path d="M4 4h4v2H4V4zm12 15h4v2h-4v-2zM2 9h10v2H2V9zm12 0h6v2h-6V9zM4 14h6v2H4v-2zm8 0h10v2H12v-2zM10 4h12v2H10V4zM2 19h12v2H2v-2z"></path></g></svg>');
  }
  &.icon-device-desktop {
    background-image: url('data:image/svg+xml;utf8,<svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><rect width="448" height="320" x="32" y="64" fill="none" stroke-linejoin="round" stroke-width="32" rx="32" ry="32"></rect><path stroke-linecap="round" stroke-linejoin="round" stroke-width="32" d="M304 448l-8-64h-80l-8 64h96z"></path><path fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="32" d="M368 448H144"></path><path d="M32 304v48a32.09 32.09 0 0032 32h384a32.09 32.09 0 0032-32v-48zm224 64a16 16 0 1116-16 16 16 0 01-16 16z"></path></svg>');
  }
  &.icon-device-tablet {
    background-image: url('data:image/svg+xml;utf8,<svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M409 39c-4.5-4.5-10.6-7-16.9-7H119.9c-6.4 0-12.4 2.5-16.9 7-4.5 4.5-7 10.6-7 16.9V456c0 6.4 2.5 12.4 7 16.9 4.5 4.5 10.6 7 16.9 7H392c6.4 0 12.4-2.5 16.9-7 4.5-4.5 7-10.6 7-16.9V55.9c.1-6.3-2.4-12.4-6.9-16.9zm-153.4 9.7c3.9 0 7 3.1 7 7s-3.1 7-7 7-7-3.1-7-7c0-3.8 3.2-7 7-7zM256 470c-7.7 0-14-6.5-14-14.1 0-7.5 6.2-14 14-14 7.7 0 14.1 6.4 14.1 14-.1 7.6-6.4 14.1-14.1 14.1zm144-38H112V80h288v352z"></path></svg>');
  }
  &.icon-device-mobile {
    background-image: url('data:image/svg+xml;utf8,<svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><rect width="256" height="480" x="128" y="16" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="32" rx="48" ry="48"></rect><path fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="32" d="M176 16h24a8 8 0 018 8h0a16 16 0 0016 16h64a16 16 0 0016-16h0a8 8 0 018-8h24"></path></svg>');
  }
  &.icon-text {
    background-image: url('data:image/svg+xml;utf8,<svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><g><path fill="none" d="M0 0h24v24H0z"></path><path d="M13 6v15h-2V6H5V4h14v2z"></path></g></svg>');
  }
  &.icon-headline {
    background-image: url('data:image/svg+xml;utf8,<svg viewBox="0 0 72 77" fill="none"  xmlns="http://www.w3.org/2000/svg"><path d="M46.9772 29H42.1372V16.88H28.8172V29H24.0172V0.639998H28.8172V12.36H42.1372V0.639998H46.9772V29Z" fill="#9A9797" /></svg>');
  }
  &.icon-bullet-list {
    background-image: url('data:image/svg+xml;utf8,<svg viewBox="0 0 109 44" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="5" cy="5" r="5" fill="#9A9797" /><circle cx="5" cy="22" r="5" fill="#9A9797" /><circle cx="5" cy="39" r="5" fill="#9A9797" /><rect x="23" y="2" width="86" height="7" rx="3.5" fill="#9A9797" /><rect x="23" y="19" width="86" height="7" rx="3.5" fill="#9A9797" /><rect x="23" y="36" width="86" height="7" rx="3.5" fill="#9A9797" /></svg>');
  }
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

button {
  border: none;
}
body,
html {
  font-family: Inter, Helvetica, sans-serif;
  font-weight: normal;
  background: #fff;
  width: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: inherit;
}

body {
  margin: 0;
}
.form {
  border-radius: 3px;
  padding: 10px 15px;
  background-color: rgba(0, 0, 0, 0.2);
}
.input {
  width: 100%;
  margin-bottom: 15px;
  padding: 7px 10px;
  border-radius: 2px;
  color: #fff;
  background-color: #554c57;
  border: none;
}
.textarea {
  width: 100%;
  margin-bottom: 15px;
  padding: 7px 10px;
  border-radius: 2px;
  color: #fff;
  background-color: #554c57;
  border: none;
}
.label {
  width: 100%;
  display: block;
}
.button {
  width: 100%;
  margin: 15px 0;
  background-color: #785580;
  border: none;
  color: #fff;
  border-radius: 2px;
  padding: 7px 10px;
  font-size: 1em;
  cursor: pointer;
}
.tooltip-component__body,
[data-tooltip]::after {
  font-family: Helvetica, sans-serif;
  background: rgba(55, 61, 73, 0.95);
  border-radius: 3px;
  bottom: 100%;
  color: #fff;
  content: attr(data-tooltip);
  display: block;
  font-size: 12px;
  left: 50%;
  line-height: normal;
  max-width: 32rem;
  opacity: 0;
  overflow: hidden;
  padding: 8px 16px;
  pointer-events: none;
  position: absolute;
  text-overflow: ellipsis;
  transform: translate(-50%, 0);
  transition: opacity 0.25s, transform 0.25s;
  white-space: nowrap;
  box-sizing: border-box;
  z-index: 10;
}
[data-tooltip-visible="true"]::after,
[data-tooltip]:focus::after,
[data-tooltip]:hover::after {
  opacity: 1;
  transform: translate(-50%, -0.5rem);
}
[data-tooltip-pos="right"]::after {
  bottom: 50%;
  left: 100%;
  transform: translate(0, 50%);
}
[data-tooltip-pos="right"]:focus::after,
[data-tooltip-pos="right"]:hover::after,
[data-tooltip-visible="true"][data-tooltip-pos="right"]::after {
  transform: translate(0.5rem, 50%);
}
[data-tooltip-pos="bottom"]::after {
  bottom: auto;
  top: 100%;
  transform: translate(-50%, 0);
}
[data-tooltip-pos="bottom"]:focus::after,
[data-tooltip-pos="bottom"]:hover::after,
[data-tooltip-visible="true"][data-tooltip-pos="bottom"]::after {
  transform: translate(-50%, 0.5rem);
}
[data-tooltip-pos="left"]::after {
  bottom: 50%;
  left: auto;
  right: 100%;
  transform: translate(0, 50%);
}
[data-tooltip-pos="left"]:focus::after,
[data-tooltip-pos="left"]:hover::after,
[data-tooltip-visible="true"][data-tooltip-pos="left"]::after {
  transform: translate(-0.5rem, 50%);
}
[data-tooltip-length="small"]::after {
  white-space: normal;
  width: 80px;
}
[data-tooltip-length="medium"]::after {
  white-space: normal;
  width: 150px;
}
[data-tooltip-length="large"]::after {
  white-space: normal;
  width: 300px;
}
[data-tooltip-length="fit"]::after {
  white-space: normal;
  width: 100%;
}
/* // IE 11 bugfix */
button[data-tooltip] {
  overflow: visible;
}
// .row {
//   display: flex;
//   justify-content: flex-start;
//   align-items: stretch;
//   flex-wrap: nowrap;
//   padding: 10px;
// }
// .cell {
//   min-height: 75px;
//   flex-grow: 1;
//   flex-basis: 100%;
//   padding: 10px;
// }
@media (max-width: 768px) {
  // .row {
  //   flex-wrap: wrap;
  // }
}

.blockbuilder-branding {
  display: none !important;
  opacity: 0 !important;
  pointer-events: none !important;
}

.ltr {
  text-align: left;
}

.rtl {
  text-align: right;
}

.editor-placeholder {
  color: #999;
  overflow: hidden;
  position: absolute;
  top: 15px;
  left: 15px;
  user-select: none;
  pointer-events: none;
}

.editor-paragraph {
  margin: 0 0 15px 0;
  position: relative;
}

.notification-side {
  background-color: white;
  width: 30vw;
  padding: 15px 0;
  border-right: 1px solid #ccc;
}
.notification-side .list-group-item {
  border: none !important;
}
.notification-side .avatar-md {
  width: 2.5rem;
  height: 2.5rem;
  font-size: 1rem;
  margin-right: 25px;
}
.main-notification {
  background-color: white;
  width: 70vw;
  padding: 10px 16px;
}
.main-notification .list-group-item-action {
  background-color: #f2f2f2;
  border: none;
  margin: 10px 0;
  border-radius: 8px !important;
}

.main-notification h5 {
  font-size: 16px;
  font-weight: 500;
  color: #000;
}
.main-notification p {
  font-size: 14px;
  font-weight: 400;
  color: #727272;
}
.main-notification .text-medium-emphasis {
  display: block;
}
.doc-box {
  width: 100px;
  height: 100px;
  border-radius: 5px;
  background-color: #fff;
  margin: auto;
  box-shadow: 0px 0px 8px 2px #ddd;
}
.notification-side small {
  float: right;
  text-transform: capitalize;
  font-size: 11px;
  color: "#777";
}
.main-notification h4 {
  color: #1f7ba9;
  margin-top: 8px;
}
.user-head {
  padding: 20px 0;
}
//   .main-notification .btn btn-primary{
// height: 50px;
// width: 100px;
//   }
.user-head .name {
  margin-left: 10px;
}
.side {
  background-color: #f2f2f2;
  height: 79vh;
  width: 20vw;
  padding: 15px 0;
  border-right: 1px solid #ccc;
}
.main {
  background-color: white;
  width: 100vw;
  padding: 30px 15px;
}
.main h1 {
  position: absolute;
  font-size: 24px;
  font-weight: 600;
}
.carousel-inner {
  overflow: visible;
}

.main .list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;
}
.main .list-group-item + .list-group-item {
  border-top-width: 0;
  border-left: none !important;
  border-right: none !important;
}
.main .list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
  border-left: none !important;
  border-right: none !important;
  border-top: none !important;
}
.ButtonWrap__root___1EO_R {
  z-index: 0 !important;
}
.searchbar input {
  font-size: 16px;
  line-height: 1.5;
  border: none;
  background: #ffffff;
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20'><path fill='%23838D99' d='M13.22 14.63a8 8 0 1 1 1.41-1.41l4.29 4.29a1 1 0 1 1-1.41 1.41l-4.29-4.29zm-.66-2.07a6 6 0 1 0-8.49-8.49 6 6 0 0 0 8.49 8.49z'></path></svg>");
  background-repeat: no-repeat;
  background-position: 10px 10px;
  background-size: 15px 15px;
  border: 1px solid #c5cbd5;
  width: 100%;
  height: 36px;
  padding: 0.5em 1em 0.5em 2.5em;
  margin-bottom: 0px;
  //margin-left: 5px;
  border-radius: 5px;
  margin-right: 15px;
}
.searchbar .btn {
  background: rgb(31, 123, 169);
  color: #ffffff;
  width: 40%;
  height: 36px;
  font-size: 13px;
  border-color: rgb(31, 123, 169);
  border-radius: 5px;
  margin-left: auto;
  display: block;
  margin: 0;
}
.avatars {
  width: 32px;
  height: 32px;
  border-radius: 100%;
  background-color: orange;
  color: white;
  text-align: center;
  line-height: 32px;
  margin-right: 8px;
}
.para {
  margin-left: 29px;
  font-size: 12px;
  color: gray;
  font-weight: 200;
}
.doc-view {
  height: 200px;
}
.doc-view svg {
  width: 80px;
  margin: auto;
}

.sub-btn {
  border-radius: 2px !important;
}
.css-1s2u09g-control {
  border-width: 2px !important;
}
.react-multi-carousel-list {
  padding: 45px 0;
}
.price-card {
  box-shadow: 4px 3px 6px 4px #eee;
  border: none !important;
  border-radius: 15px;
  padding: 10px;
  width: 85%;
  height: 18rem;
  align-items: center;
  margin: auto;
}
.card-User {
  font-size: 22px;
  font-weight: 500;
  color: #757575;
}

.price-card:hover {
  transform: scale(1.1);
  transition-duration: 0.3s;
  background-color: #000;
  color: #fff;
}
.price-card:hover .card-title {
  color: #fff !important;
}
.integration .card-title {
  font-size: 18px;
  margin: 15px 0;
  font-weight: 600;
  text-transform: capitalize;
}
.CircularProgressbar {
  height: 20vh !important;
  padding-top: 10px !important;
}
.CircularProgressbar .CircularProgressbar-path {
  stroke: #1f7ba9 !important;
  stroke-linecap: round;
  transition: stroke-dashoffset 0.5s ease 0s;
}
.userProfile {
  height: 120px;
  width: 120px;
  border-radius: 100px;
  border-width: 4px;
  border-color: rgb(31, 123, 169);
  display: flex;
  justify-content: center;
  align-items: center;
}
.userProfileBox {
  align-items: center;
  justify-content: space-between;
  width: 100%;
  background: white;
  padding: 20px;
  border-radius: 8px;
  position: relative;
}
.coinBox {
  background: #ddd;
  border-radius: 10px;
  padding: 10px;
}
.user-name {
  font-size: 14px;
  color: #000;
  font-weight: 500;
}
.short-msg {
  font-size: 13px;
  color: #858585;
  font-weight: 400;
}

//dropdown style//
body {
  background: whitesmoke;
}

.rec-component {
  text-align: center;
  margin: 15px 0 30px;
}
.m-profile {
  padding: 15px;
  border-radius: 15px;
  background: white !important;
  color: #424242;
  padding: 10px;
  border-radius: 5px;
  margin: 15px 0 30px;
}
.modal-label {
  font-size: 16px;
  font-weight: 600;
}
.m-profile svg {
  width: 60px;
  color: #fff;
}
.m-profile1 svg {
  width: 60px;
  color: #fff;
}
.m-profile1 {
  width: 120px;
  border: 2px solid #fff;
  height: 120px;
  display: flex;
  border-radius: 100px;
  align-items: center;
  justify-content: center;
  background: #1f7ba9;
}
.m-profile2 {
  width: 120px;
  border: 2px solid #fff;
  height: 120px;
  display: flex;
  border-radius: 100px;
  margin-right: 20px;
  align-items: center;
  justify-content: center;
  background: #1f7ba9;
}
.pro-info label {
  font-size: 16px;
  color: #424242;
  font-weight: 600;
}
.pro-info h3 {
  font-size: 16px;
  color: #424242;
}
.pro-info1 label {
  font-size: 16px;
  color: #424242;
  font-weight: 600;
}
.pro-info1 h3 {
  font-size: 16px;
  color: #424242;
}

/**
 * Main wrapper
 */
.select-search {
  width: 100%;
  position: relative;
  box-sizing: border-box;
}

.select-search *,
.select-search *::after,
.select-search *::before {
  box-sizing: inherit;
}

/**
* Value wrapper
*/
.select-search__value {
  position: relative;
  z-index: 1;
}

.select-search__value::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: calc(50% - 9px);
  right: 19px;
  width: 11px;
  height: 11px;
}

/**
* Input
*/
.select-search__input {
  display: block;
  height: 36px;
  width: 100%;
  padding: 0 40px 0 16px;
  background: #fff;
  border: 1px solid transparent;
  box-shadow: 0 0.0625rem 0.125rem rgba(0, 0, 0, 0.15);
  border-radius: 3px;
  outline: none;
  font-family: "Noto Sans", sans-serif;
  font-size: 14px;
  text-align: left;
  text-overflow: ellipsis;
  line-height: 36px;
  -webkit-appearance: none;
}

.select-search__input::-webkit-search-decoration,
.select-search__input::-webkit-search-cancel-button,
.select-search__input::-webkit-search-results-button,
.select-search__input::-webkit-search-results-decoration {
  -webkit-appearance: none;
}

.select-search__input:not([readonly]):focus {
  cursor: initial;
}

/**
* Options wrapper
*/
.select-search__select {
  background: #fff;
  box-shadow: 0 0.0625rem 0.125rem rgba(0, 0, 0, 0.15);
}

/**
* Options
*/
.select-search__options {
  list-style: none;
  padding: 0;
}

/**
* Option row
*/
.select-search__row:not(:first-child) {
  border-top: 1px solid #eee;
}

/**
* Option
*/
.select-search__option,
.select-search__not-found {
  display: block;
  height: 36px;
  width: 100%;
  padding: 0 16px;
  background: #fff;
  border: none;
  outline: none;
  font-family: "Noto Sans", sans-serif;
  font-size: 14px;
  text-align: left;
  cursor: pointer;
}

.select-search--multiple .select-search__option {
  height: 48px;
}

.select-search__option.is-selected {
  background: #00699f;
  color: #fff;
}

.select-search__option.is-highlighted,
.select-search__option:not(.is-selected):hover {
  background: rgba(47, 204, 139, 0.1);
}

.select-search__option.is-highlighted.is-selected,
.select-search__option.is-selected:hover {
  background: #00699f;
  color: #fff;
}

/**
* Group
*/
.select-search__group-header {
  font-size: 10px;
  text-transform: uppercase;
  background: #eee;
  padding: 8px 16px;
}

/**
* States
*/
.select-search.is-disabled {
  opacity: 0.5;
}

.select-search.is-loading .select-search__value::after {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='50' height='50' viewBox='0 0 50 50'%3E%3Cpath fill='%232F2D37' d='M25,5A20.14,20.14,0,0,1,45,22.88a2.51,2.51,0,0,0,2.49,2.26h0A2.52,2.52,0,0,0,50,22.33a25.14,25.14,0,0,0-50,0,2.52,2.52,0,0,0,2.5,2.81h0A2.51,2.51,0,0,0,5,22.88,20.14,20.14,0,0,1,25,5Z'%3E%3CanimateTransform attributeName='transform' type='rotate' from='0 25 25' to='360 25 25' dur='0.6s' repeatCount='indefinite'/%3E%3C/path%3E%3C/svg%3E");
  background-size: 11px;
}

.select-search:not(.is-disabled) .select-search__input {
  cursor: pointer;
}

/**
* Modifiers
*/
.select-search--multiple {
  border-radius: 3px;
  overflow: hidden;
}

.select-search:not(.is-loading):not(.select-search--multiple)
  .select-search__value::after {
  transform: rotate(45deg);
  border-right: 1px solid #000;
  border-bottom: 1px solid #000;
  pointer-events: none;
}

.select-search--multiple .select-search__input {
  cursor: initial;
}

.select-search--multiple .select-search__input {
  border-radius: 3px 3px 0 0;
}

.select-search--multiple:not(.select-search--search) .select-search__input {
  cursor: default;
}

.select-search:not(.select-search--multiple) .select-search__input:hover {
  border-color: #00699f;
}

.select-search:not(.select-search--multiple) .select-search__select {
  position: absolute;
  z-index: 2;
  top: 44px;
  right: 0;
  left: 0;
  border-radius: 3px;
  overflow: auto;
  max-height: 360px;
}

.select-search--multiple .select-search__select {
  position: relative;
  overflow: auto;
  max-height: 260px;
  border-top: 1px solid #eee;
  border-radius: 0 0 3px 3px;
}

.select-search__not-found {
  height: auto;
  padding: 16px;
  text-align: center;
  color: #888;
}

//dropdown style//
.invite-box {
  width: 50%;
  box-shadow: 4px 3px 6px 4px #eee;
  border-radius: 10px;
  margin: 90px auto 45px;
  height: 15rem;
  padding: 15px 30px;
}
.date-time input {
  width: 100%;
  border: 1px solid #eee;
  box-shadow: 0 0.0625rem 0.125rem rgb(0 0 0 / 15%);
  border-radius: 3px;
  height: 36px;
  text-indent: 10px;
  margin-bottom: 15px;
}
.loaders svg {
  margin: auto !important;
}

.paymentElement input,
select {
  display: block;
  border: none;
  font-size: 18px;
  margin: 10px 0 20px 0;
  max-width: 100%;
  padding: 10px 14px;
  box-shadow: rgba(50, 50, 93, 0.14902) 0px 1px 3px,
    rgba(0, 0, 0, 0.0196078) 0px 1px 0px;
  border-radius: 4px;
  background: white;
  color: #424770;
  letter-spacing: 0.025em;
  width: 500px;
}

.paymentElement input::placeholder {
  color: #aab7c4;
}

.paymentElement .result,
.error {
  font-size: 16px;
  font-weight: bold;
  margin-top: 10px;
  margin-bottom: 20px;
}

.paymentElement .error {
  color: #e4584c;
}

.paymentElement .result {
  color: #666ee8;
}

/*
The StripeElement class is applied to the Element container by default.
More info: https://stripe.com/docs/stripe-js/reference#element-options
*/

.StripeElement,
.StripeElementIdeal,
.StripeElementP24,
.StripeElementEPS,
.StripeElementFpx {
  display: block;
  margin: 10px 0 20px 0;
  max-width: 500px;
  padding: 10px 14px;
  box-shadow: rgba(50, 50, 93, 0.14902) 0px 1px 3px,
    rgba(0, 0, 0, 0.0196078) 0px 1px 0px;
  border-radius: 4px;
  background: white;
}

.StripeElement--focus,
.StripeElementIdeal--focus,
.StripeElementP24--focus,
.StripeElementEPS--focus,
.StripeElementFpx--focus {
  box-shadow: rgba(50, 50, 93, 0.109804) 0px 4px 6px,
    rgba(0, 0, 0, 0.0784314) 0px 1px 3px;
  -webkit-transition: all 150ms ease;
  transition: all 150ms ease;
}

.StripeElement.loading {
  height: 41.6px;
  opacity: 0.6;
}

.StripeElementIdeal,
.StripeElementP24,
.StripeElementEPS,
.StripeElementFpx {
  padding: 0;
}
.MeemBox {
  border: 2px solid #fff;
  background-color: #1f7ba9;
  margin: 30px auto 0;
  width: 50%;
  padding: 10px;
  border-radius: 8px;
}
.RichTextEditor__root___2QXK- {
  margin-left: 30px !important;
  margin-bottom: 20px;
  width: 63% !important;
}
.rc-edit .RichTextEditor__root___2QXK- {
  margin-left: 0px !important;
  margin-bottom: 20px;
  width: 100% !important;
  height: 350px;
}
// .ButtonGroup__root___3lEAn{
//   width: 15% !important;
// }
.rdt_TableCell {
  font-weight: 500;
}
.rdt_TableCol:first-child {
  border-top-left-radius: 12px;
}
.rdt_TableCol:last-child {
  border-top-right-radius: 12px;
}
.rdt_Table {
  border-radius: 20px !important;
  border: 1px solid #eee;
  box-shadow: 0px 3px 6px #272d3b33;
}
.password-eye {
  position: absolute;
  top: 13px;
  right: 17px;
  cursor: pointer;
}

// .striped-table .rdt_TableRow:nth-child(even) {
//   background-color: #f2f2f2;
// }
// .striped-table .dcCEdk{
// border-radius: 10px;
// }
// .striped-table .rdt_TableHeadRow{
//   background-color: #f2f2f2;
// }

.form-card {
  box-shadow: 4px 3px 6px 4px #eee;
  height: auto !important;
  padding: 30px 120px;
  margin: 60px auto;
  border: none;
  width: 80%;
}
.form-card h1 {
  font-size: 24px;
  margin-bottom: 30px;
  font-weight: 600;
}
.a-table table {
  width: 100% !important;
}
.a-table table th {
  padding: 10px 16px;
  background: #eee;
}
.a-table table thead tr {
  background: #eee;
}

.a-table table td {
  padding: 10px 16px;
}

.f-editor .RichTextEditor__root___2QXK- {
  margin-left: 0px !important;
  margin-bottom: 20px;
  width: 100% !important;
}

.bar {
  fill: steelblue;
}

.axis {
  font: 10px sans-serif;
}

.axis path,
.axis line {
  fill: none;
  stroke: #000;
  shape-rendering: crispEdges;
}

.x.axis path {
  fill: none;
  stroke: #000;
  shape-rendering: crispEdges;
}
.rdt_Pagination .jygXwu {
  width: 8%;
}
.rdt_Pagination select {
  padding-right: 0px;
}
.card-widget3 {
  width: 100%;
  padding: 15px;
  border-radius: 15px;
  box-shadow: 0 0 16px 1px rgba(179, 176, 176, 0.5);
  margin-bottom: 15px;
}
.card-widget3 p {
  margin-bottom: 10px;
}
.card-widget1 {
  width: 100%;
  padding: 15px;
  border-radius: 15px;
  box-shadow: 0 0 16px 1px rgba(179, 176, 176, 0.1);
  margin-bottom: 15px;
}
.card-widget1 h2 {
  font-size: 24px;
  color: white;
  font-weight: 600;
}
.card-widget1 p {
  font-size: 15px;
  color: white;
  font-weight: 500;
}
.loader-block svg {
  margin: auto;
}
.card-widget {
  width: 19%;
  padding: 20px 10px;
  border-radius: 15px;
  box-shadow: 0 0 16px 1px rgb(109 100 100 / 20%);
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.card-widget h2 {
  font-size: 24px;
  color: white;
  font-weight: 600;
}
.card-widget p {
  font-size: 15px;
  color: white;
  font-weight: 500;
}

.card-widget .icon {
  color: white;
}
.icon-wid {
  border: 2px solid #fff;
  border-radius: 100%;
  width: 42px;
  height: 42px;
  text-align: center;
  line-height: 50px;
}
.tem-section {
  background-color: white !important;
  border: 1px solid #eee;
  border-radius: 10px;
  padding: 16px 15px;
  box-shadow: 0 0 16px 1px rgb(109 100 100 / 20%);
}
.bar-widget {
  background-color: white !important;
  border-radius: 15px;
  box-shadow: 0 0 16px 1px rgb(109 100 100 / 20%);
}
.icon-wid svg {
  color: white;
  margin: 4px auto;
}
.date-filter {
  border: 0.5px solid black;
  margin-left: -135px;
  border-radius: 4px;
  padding: 8px;
  min-height: 20px;
}

.filter-label {
  margin-top: -18px;
  margin-left: 15px;
}

// .reset {
// border: 0.5px solid black;
// width: 75px;
// margin-left: 10px;
// margin-top: 7px;
// height: 25px;
// text-align: center;
// border-radius: 4px;
// background-color: #f3b531;
// color: white;
// }
.rst {
  height: 38px;
  cursor: pointer;
  margin-left: 5px;
}
